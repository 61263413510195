<template>
  <div class="home">
    <el-container>
      <el-header>
        <div class="container">
          <h1>
            <img src="../assets/mainlogo.png">
          </h1>
          <ul class="nav"></ul>
        </div>
      </el-header>
      <el-main>
        <el-row>
          <div class="data-row" v-for="(val,idx) in serverdata" :key="idx">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{val.name}}</span>
              </div>
              <div class="text item">
                <table style="width: 100%">
                  <tbody>
                    <tr>
                      <td style="width: 20%">在线时间</td>
                      <td style="width: 80%">{{val.uptime | sectoday}}天</td>
                    </tr>
                    <tr>
                      <td>负载</td>
                      <td>{{val.cpu.load}}</td>
                    </tr>
                    <tr>
                      <td>CPU使用率</td>
                      <td>
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="val.cpu.usage" v-if="val.cpu.usage<20" status="success"></el-progress>
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="val.cpu.usage" v-else-if="val.cpu.usage>=20 && val.cpu.usage<50"></el-progress>
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="val.cpu.usage" v-else-if="val.cpu.usage>=50 && val.cpu.usage<70" status="warning"></el-progress>
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="val.cpu.usage" v-else-if="val.cpu.usage>=70" status="exception"></el-progress>
                      </td>
                    </tr>
                    <tr>
                      <td>内存</td>
                      <td>
                        <el-progress :text-inside="true" :stroke-width="26" 
                        :percentage="val.memory.used/val.memory.total | fixednumber"
                        :show-text="true"
                        ></el-progress>
                      </td>
                    </tr>
                    <tr>
                      <td>SWAP</td>
                      <td>
                        <el-progress :text-inside="true" :stroke-width="26" 
                        v-if="val.swap.total>0"
                        :percentage="val.swap.used/val.swap.total | fixednumber"
                        :show-text="true"
                        ></el-progress>
                        <span v-else>0KB</span>
                      </td>
                    </tr>
                    <tr>
                      <td>储存</td>
                      <td>
                        <el-progress :text-inside="true" :stroke-width="26" 
                        :percentage="val.disk.used/val.disk.total | fixednumber"
                        :show-text="true"
                        ></el-progress>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-card>
          </div>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      serverdata: []
    };
  },
  mounted: function (){
    this.getserverdata()
    setInterval(() => {
      this.getserverdata()
    }, 10000);
    
  },
  methods: {
    getserverdata(){
      var that=this
      that.$axios
        .post(
          "https://stats.aic.ltd/api.json",
          {
            action: "get",
          }
        )
        .then(function (response) {
          if(response.data.status==200) {
            that.serverdata = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      
    },
    format(used,total,type){
      if(total ===0 ) return '0 KB'
      var k = 1024
      let sizes
      if(type=="memory" || type=="swap"){
        sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      }else{
        sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      }
      
      var used_i = Math.floor(Math.log(used) / Math.log(k))
      var total_i = Math.floor(Math.log(total) / Math.log(k))
      used = (used / Math.pow(k, used_i)).toPrecision(3) + ' ' + sizes[used_i]
      total = (total / Math.pow(k, total_i)).toPrecision(3) + ' ' + sizes[total_i]
      return used + " / "+ total
    }
  },
  filters:{
    sectoday: function(sec){
      sec = sec/3600/24
      return sec.toFixed(0)
    },
    fixednumber: function(number){
      number=number*100
      number = parseFloat(number).toFixed(2)
      number = number * 1 - 0
      return number
    }
  }
}
</script>
<style>
.el-header{
  height: 80px;
  background-color: #fff;
  color: #fff;
  top: 0;
  left: 0;
  width: 90%;
  line-height: 80px;
  z-index: 100;
  position: relative;
  margin: 0 auto;
}
.container{
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.el-header h1{
  margin: 0;
  float: left;
  font-size: 32px;
  font-weight: 400;
}
.el-header .nav {
    float: right;
    height: 100%;
    line-height: 80px;
    background: transparent;
    padding: 0;
    margin: 0;
}
.el-main{
  width: 90%;
  margin: 0 auto;
}
.box-card{
  margin: 5px
}
@media (min-width: 1024px) {
  .data-row {
    float: left;
    width: 25%;
  }
}
@media (min-width: 600px) and (max-width: 1024px){
  .data-row {
    float: left;
    width: 50%;
  }
}
@media (max-wiedh: 600px) {
  .data-row {
    float: left;
    width: 100%;
  }
}

</style>